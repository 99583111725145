import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import babyImage from "../../images/journey/baby.jpg";
import ogImage from "../../images/og/journey-beginning.jpg";

const JourneyPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="My Journey - matthewgoslett.com"
      chapter={CHAPTERS.IN_THE_BEGINNING}
      og={{ image: ogImage }}
    >
      <p>
        I was born at Mowbray Maternity Hospital in Cape Town, South Africa on
        the 31st of July, 1987 to Peter & Pam Goslett (née Brown).
      </p>
      <JourneyImage image={babyImage} />
    </JourneyPageLayout>
  );
};

export default JourneyPage;
